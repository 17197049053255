











































































































































































.company-settings {
  &-group {
    padding: 20px 0;

    &:not(:last-child) {
      border-bottom: 1px solid #cecece;
    }


    &-name {
      font-weight: bold;
    }

    &-row {
      padding: 10px 0;
      display: flex;
      justify-content: space-between;
      gap: 2rem;
    }
  }
}
